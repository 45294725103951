import React from "react";
import './style.css'

export default function Section2() {
  return (
    <div>
      <div className="sect2">
        <div className="container">
          <div className="sect2__blocks">
            <div className="sect2__txt">
              <h1>Why should you book a service with BOBRIKOVCLEAN?</h1>
            </div>
            <div className="sect2__com-card pt-3">
              <div className="sect2__com-card1">
                <p>
                At Bobrikovclean, our dedicated professionals provide premium cleaning, pressure washing, carpet steam cleaning, and refinishing services throughout Central Virginia. With over 5 years of experience, we are locally owned, licensed, and insured, making us a trusted name in the industry. Don’t delay – call us now or fill out our form to schedule an appointment.
                </p>
              </div>
              <div className="sect2__com-card2">
                <div className="sect2__card2-item">
                  <i class="fa-solid fa-check"></i>
                  <p>Certified and highly trained professionals</p>
                </div>
                <div className="sect2__card2-item">
                  <i class="fa-solid fa-check"></i>
                  <p>Fully licensed and insured</p>
                </div>
                <div className="sect2__card2-item">
                  <i class="fa-solid fa-check"></i>
                  <p>
                    Offering a range of specialized services, so you only deal
                    with one company!
                  </p>
                </div>
                <div className="sect2__card2-item">
                  <i class="fa-solid fa-check"></i>
                  <p>Free estimates provided</p>
                </div>
                <div className="sect2__card2-item">
                  <i class="fa-solid fa-check"></i>
                  <p>Proudly locally owned</p>
                </div>
                <div className="sect2__card2-item">
                  <i class="fa-solid fa-check"></i>
                  <p>More than 10 years of experience</p>
                </div>
              </div>
            </div>
            <button className="sect1__sch sect2__call" onClick={() => (window.location.href = "tel:+12532046181")}>Click to call</button>
          </div>
        </div>
      </div>
    </div>
  );
}
