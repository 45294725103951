import React from "react";
import Navbar from "../component/Navbar/Navbar";
import Modal_Galleery from "../component/Modal_Galleery/Modal_Galleery";
import Footer from "../component/Footer/Footer";
import SupportButton from '../component/Support_btn/Support_btn'

export default function Galleery() {
  return (
    <div>
      <Navbar />
      <Modal_Galleery />
      <Footer/>
      <SupportButton/>
    </div>
  );
}
