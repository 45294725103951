import Footer from "./component/Footer/Footer";
import Navbar from "./component/Navbar/Navbar";
import Secton1 from "./component/Section/Secton1";
import Section2 from "./component/Section2/Section2";
import Section3 from "./component/Section3/Section3";
import Section4 from "./component/Section4/Section4";
import Section5 from "./component/Section5/Section5";
import Section6 from "./component/Section6/Section6";
import { Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import Galleery from "./pages/Galleery";
import Reviews_page from "./pages/Reviews_page";
import Contact_page from "./pages/Contact_page";
import ScrollReset from "./ScrollReset";

function App() {
  return (
    <div className="App">
      <ScrollReset/>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/galleery" element={<Galleery/>} />
        {/* <Route path="/reviews" element={<Reviews_page/>} /> */}
        <Route path="/contact" element={<Contact_page/>} />
      </Routes>
    </div>
  );
}

export default App;
