import React from "react";
import "./style.css";
import img from "./img/1.jpg";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

export default function Section5() {
  const questions = [
    {
      question: "Why choose our dry cleaning service?",
      answer:
        "We offer high-quality dry cleaning services for cars, apartments, carpets and furniture using professional equipment and safe means. Our team consists of experienced professionals who carefully and accurately carry out their work to ensure perfect cleanliness and freshness.",
    },
    {
      question: "Do you have any quality certificates?",
      answer:
        "Yes, all our employees have received special training and certification in the field of dry cleaning, and the products we use are certified and meet high safety standards.",
    },
    {
      question: "What products do you use for cleaning?",
      answer:
        "We use only safe, environmentally friendly products that do not harm either health or the environment. Our solutions are carefully selected for effective and delicate cleaning.",
    },
    {
      question: "Is it possible to order dry cleaning at home?",
      answer:
        "Of course! We offer on-site dry cleaning services. Our team will arrive at a convenient time for you and perform all the necessary work on site.",
    },
    {
      question: "How long does the cleaning process take?",
      answer:
        "The lead time depends on the complexity and amount of work. As a rule, dry cleaning of one apartment or car takes from 2 to 5 hours.",
    },
    {
      question:
        "How long will it take for carpets/furniture to dry after cleaning?",
      answer:
        "The drying process usually takes from 3 to 6 hours, depending on the humidity and temperature in the room.",
    },
    {
      question: "What guarantees do you provide?",
      answer:
        "We guarantee a high level of service and cleaning quality. If you are not completely satisfied with the result, we will re-process it for free.",
    },
    {
      question: "Is it possible to get advice before ordering?",
      answer:
        "Of course! Our specialists are ready to answer all your questions and advise you on any aspects of the services.",
    },
  ];

  return (
    <div>
      <div className="sect5  mb-3 ">
        <div className="container">
          <div className="sect5__blocks">
            <div className="sect5__bllock2-com">
              <div className="sect5__block2">
                <img src={img} alt="" />
              </div>
            </div>
            <div className="sect5__block1">
              <h2>Learn More About BobrikovClean.</h2>
              <p>
                BobrikovClean proudly serves the Central Virginia area offering
                professional cleaning of carpet, tile, upholstery, patio, duct,
                window and dryer vents. We are fully licensed and insured, and
                our certified technicians guarantee the highest quality service.
                Contact us today for a free, no-obligation estimate. 
              </p>
              <Link to={'/Contact'}>
              <button className="sect1__sch sect5__ab mt-3">Contact</button></Link>
            </div>
          </div>

          <div className="sect5__question mt-5">
            {questions.map((item, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {/* Кастомный размер текста для вопросов */}
                  <Typography
                    sx={{
                      fontSize: "clamp(15px,5vw,20px)",
                      fontWeight: "bold",
                    }}
                  >
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* Кастомный размер текста для ответов */}
                  <Typography sx={{ fontSize: "20px", color: "#555" }}>
                    {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
