import React, { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import FormLabel from "@mui/joy/FormLabel";
import Radio from "@mui/joy/Radio";
import RadioGroup from "@mui/joy/RadioGroup";
import Sheet from "@mui/joy/Sheet";
import CircularProgress from "@mui/material/CircularProgress"; // Импортируем индикатор загрузки
import img1 from "./Img/1.jpg";
import img2 from "./Img/2.jpg";
import video1 from "./Img/videos/1.mp4";
import video2 from "./Img/videos/2.mp4";
import video3 from "./Img/videos/3.mp4";
import video4 from "./Img/videos/4.mp4";
import video5 from "./Img/videos/5.mp4";
import video6 from "./Img/videos/6.mp4";
import video7 from "./Img/videos/7.mp4";
import video8 from "./Img/videos/8.mp4";
import video9 from "./Img/videos/9.mp4";
import video10 from "./Img/videos/10.mp4";
import video11 from "./Img/videos/11.mp4";
import video12 from "./Img/videos/12.mp4";
import preview1 from "./Img/photo/1.jpg"; // Превью для видео 1
import preview2 from "./Img/photo/2.jpg"; // Превью для видео 2
import preview3 from "./Img/photo/3.jpg"; // Превью для видео 3
import preview4 from "./Img/photo/4.jpg";
import preview5 from "./Img/photo/5.jpg"; // Превью для видео 3
import preview6 from "./Img/photo/6.jpg"; // Превью для видео 3
import preview7 from "./Img/photo/7.jpg"; // Превью для видео 3
import preview8 from "./Img/photo/8.jpg"; // Превью для видео 3
import preview9 from "./Img/photo/9.jpg"; // Превью для видео 3
import preview10 from "./Img/photo/10.jpg"; // Превью для видео 3
import preview11 from "./Img/photo/11.jpg"; // Превью для видео 3
import preview12 from "./Img/photo/12.jpg"; // Превью для видео 4
import "./style.css";

const initialData = [
  { id: 1, type: "image", src: img1, alt: "Image 1" },
  { id: 2, type: "image", src: img2, alt: "Image 2" },
  { id: 3, type: "video", src: video1, alt: "Video 1", poster: preview1 },
  { id: 4, type: "video", src: video2, alt: "Video 2", poster: preview2 },
  { id: 5, type: "video", src: video3, alt: "Video 3", poster: preview3 },
  { id: 6, type: "video", src: video4, alt: "Video 4", poster: preview4 },
  { id: 7, type: "video", src: video5, alt: "Video 5", poster: preview5 },
  { id: 8, type: "video", src: video6, alt: "Video 6", poster: preview6 },
  { id: 9, type: "video", src: video7, alt: "Video 7", poster: preview7 },
  { id: 10, type: "video", src: video8, alt: "Video 8", poster: preview8 },
  { id: 11, type: "video", src: video9, alt: "Video 9", poster: preview9 },
  { id: 12, type: "video", src: video10, alt: "Video 10", poster: preview10 },
  { id: 13, type: "video", src: video11, alt: "Video 11", poster: preview11 },
  { id: 14, type: "video", src: video12, alt: "Video 12", poster: preview12 },
];

export default function Modal_Gallery() {
  const [galleryItems] = useState(initialData);
  const [visibleItems, setVisibleItems] = useState(4); // Показывать 4 элемента
  const [selectedImage, setSelectedImage] = useState(null); // Для модального окна
  const [mediaType, setMediaType] = useState("video"); // Фильтрация по типу медиа
  const [loading, setLoading] = useState(Array(initialData.length).fill(true)); // Устанавливаем состояние загрузки

  // Функция для проверки загрузки видео из localStorage
  useEffect(() => {
    const savedLoadingState = JSON.parse(
      localStorage.getItem("videoLoadingState")
    );
    if (savedLoadingState) {
      setLoading(savedLoadingState);
    }
  }, []);

  // Сохраняем состояние загрузки в localStorage
  useEffect(() => {
    localStorage.setItem("videoLoadingState", JSON.stringify(loading));
  }, [loading]);

  const handleLoadMore = () => {
    setVisibleItems((prev) => prev + 4); // Загрузить еще 4 элемента
  };

  const handleItemLoad = (index) => {
    const newLoadingState = [...loading];
    newLoadingState[index] = false; // Снять состояние загрузки после полной загрузки элемента
    setLoading(newLoadingState);
  };

  const openImageModal = (src) => {
    setSelectedImage(src); // Открыть модальное окно с изображением
  };

  return (
    <div>
      <div className="gall"></div>
      <div className="container mb-5">
        <div className="gallery__wrapper gall2">
          <div className="gall__text-main">
            <p>
              Look at the examples of our work! We are proud of the quality of
              our services and guarantee excellent results. Our experienced
              specialists use modern equipment and environmentally friendly
              products that carefully clean your furniture, preserving its
              original appearance and color. We offer a wide range of dry
              cleaning services for upholstered furniture, including dry and wet
              cleaning, deep cleaning and removal of complex stains. Contact us
              and we will be happy to help you return your furniture to
              cleanliness and freshness.
            </p>
          </div>

          <div className="gallery__content">
            {galleryItems
              .filter((item) => item.type === mediaType) // Фильтрация по выбранному типу медиа
              .slice(0, visibleItems)
              .map((item, index) =>
                item.type === "image" ? (
                  <div key={item.id} className="gallery__item">
                    <img
                      src={item.src}
                      alt={item.alt}
                      className={`gallery__image ${
                        loading[index] ? "hidden" : ""
                      }`}
                      onLoad={() => handleItemLoad(index)} // Скрыть индикатор после загрузки
                      onClick={() => openImageModal(item.src)} // Открыть модалку только после полной загрузки
                    />
                  </div>
                ) : (
                  <div key={item.id} className="gallery__item">
                    <video
                      controls
                      playsInline
                      poster={item.poster} // Отображение превью перед воспроизведением
                      className={`gallery__video ${
                        loading[index] ? "hidden" : ""
                      }`}
                      onLoadedData={() => handleItemLoad(index)} // Скрыть индикатор после загрузки видео
                    >
                      <source src={item.src} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )
              )}
           
          </div>
           {/* Кнопка "Загрузить больше" */}
           {visibleItems <
              galleryItems.filter((item) => item.type === mediaType).length && (
              <div className="modalg__btns">
                <button className="load-more" onClick={handleLoadMore}>
                  Load More
                </button>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
