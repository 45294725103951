import React from "react";
import img1 from "./img/2.jpg"; // Импортируем изображение
import "./style.css";
import logo from "./img/logo.webp";
import ins from "./img/insta.svg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const AnimatedButton = styled(Button)(({ theme }) => ({
  color: "white",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    color: "white",
    opacity: 1,
  },
}));

export default function Navbar() {
  const location = useLocation();

  const handleLogoClick = () => {
    if (location.pathname === "/") {
      // Если уже на главной, перезагрузить страницу
      window.location.reload();
    }
    // Иначе переход на главную будет происходить по ссылке
  };
  const [selectedService, setSelectedService] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-transparent w-100">
        <div className="container">
          <Link className="Link" to={"/"} onClick={handleLogoClick}>
            <div className="nav__logo">
              <h1>Bobrikovclean</h1>
              <p>Deep cleaning</p>
            </div>
          </Link>

          <button
            className={`navbar-toggler custom-toggler ${
              menuOpen ? "open" : ""
            }`}
            type="button"
            aria-expanded={menuOpen}
            aria-label="Toggle navigation"
            onClick={handleMenuToggle}
          >
            <span className="navbar-toggler-icon">
              {menuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="white"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 1 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="white"
                  className="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12.5A.5.5 0 0 1 3 12h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-5A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-5A.5.5 0 0 1 3 2h10a.5.5 0 0 1 0 1H3ha.5.5 0 0 1-.5-.5z"
                  />
                </svg>
              )}
            </span>
          </button>
          <div
            className={`collapse navbar-collapse nav__fon ${
              menuOpen ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >
            <div className="contact-icons contact-icons2">
              <a href="tel:+19455991844" className="contact-icon">
                <i className="fab fa-viber fa-3x contact__viber"></i>
              </a>

              {/* <a href="viber://chat?number=%2B19455991844" className="contact-icon">
      <i className="fab fa-viber fa-2x"></i> 
    </a> */}

              <a href="https://wa.me/19455991844" className="contact-icon">
                <i className="fab fa-whatsapp fa-3x "></i>
              </a>
            </div>
            <div className="nav__ikonka-insta ms-1 pt-4">
              <Link
                className="Link link"
                to="https://www.instagram.com/clean.bobrikovllc/?igsh=Y2lqcmg4MDltZDJi"
              >
                <div className="nav__logo-inst">
                  <i className="fab fa-instagram contact-icon nav-icon-insta"></i>
                  <p>EN</p>
                </div>
              </Link>
              <Link
                className="Link"
                to="https://www.instagram.com/bobrikov_clean/"
              >
                <div className="nav__logo-inst">
                  <i className="fab fa-instagram contact-icon nav-icon-insta"></i>
                  <p>RU</p>
                </div>
              </Link>
            </div>

            <button
              className="sect1__sch mt-0 nav__btn-click"
              onClick={() => (window.location.href = "tel:+12532046181")}
            >
              Click to call
            </button>

            {/* <button
              className="btn btn-outline-success nav__btn-sch"
              type="submit"
            >
              Schedule an Appointment
            </button> */}
          </div>
        </div>
      </nav>
      <div className="nav__block_siniy">
        <div className="container">
          <div className="nav__siniy-cards">
            <Link className="link Link" to="/" onClick={handleLogoClick}>
              <div className="nav__block-siniy-txt">
                <AnimatedButton>Home</AnimatedButton>
              </div>
            </Link>
            <Link className="link Link" to="/galleery">
              <div className="nav__block-siniy-txt">
                <AnimatedButton>Galleery</AnimatedButton>
              </div>
            </Link>
            {/* <Link className="link Link" to="/reviews">
              <div className="nav__block-siniy-txt">
                <AnimatedButton>Reviews</AnimatedButton>
              </div>
            </Link> */}
            <Link className="link Link" to="/contact">
              <div className="nav__block-siniy-txt">
                <AnimatedButton>Contact</AnimatedButton>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
