import React from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./style.css";

export default function Contact() {
  return (
    <>
      <div className="gall"></div>

      <div className="container gall2">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="Contact">
              <Box sx={{ textAlign: "center", padding: "20px" }}>
                <Typography variant="h4" gutterBottom>
                  Contacts for communication
                </Typography>

                {/* Иконки связи */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div className="contact__contacts">
                    <a href="tel:+12532046181" className="contact-icon">
                      <i className="fab fa-viber fa-3x contact__viber"></i>
                    </a>
                    <a
                      href="https://wa.me/19455991844"
                      className="contact-icon"
                    >
                      <i className="fab fa-whatsapp fa-3x"></i>
                    </a>
                  </div>
                </Box>

              <div className="contact__insta-icon2">
                
                  {/* Ссылки на Instagram с текстами */}
                  <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <IconButton
                    href="https://www.instagram.com/clean.bobrikovllc/?igsh=Y2lqcmg4MDltZDJi"
                    color="secondary"
                  >
                    <InstagramIcon fontSize="large" />
                  </IconButton>

                  <Typography variant="body1" color="textSecondary">
                    (EN)
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <IconButton
                    href="https://www.instagram.com/bobrikov_clean/"
                    color="secondary"
                  >
                    <InstagramIcon fontSize="large" />
                  </IconButton>

                  <Typography variant="body1" color="textSecondary">
                    (RU)
                  </Typography>
                </Box>
              </div>

                {/* Кнопка для звонка */}
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: "30px" }} 
                  onClick={() => (window.location.href = "tel:+12532046181")}
                >
                  Click to call
                </Button>
              </Box>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3220621.300572321!2d-82.06128188946747!3d37.97390891994376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884cd670bdbcb2cd%3A0xc04e4149b746a695!2z0JLQuNGA0LTQttC40L3QuNGPLCDQodCo0JA!5e0!3m2!1sen!2s!4v1729101746684!5m2!1sen!2s"
              width="100%"
              height="450"
              style={{ border: "0", maxWidth: "600px", width: "100%" }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
