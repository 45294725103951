import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import "./style.css";
import axios from "axios";






const SupportButton = () => {
  const [showAnimatedButton, setShowAnimatedButton] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    phone: "",
    name: "",
    question: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setShowAnimatedButton(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `
    Новая заявка
      Phone: ${formData.phone}
      Name: ${formData.name}
      Question: ${formData.question}
    `;

    // Блокируем кнопку и задаем таймер на 30 секунд
    setIsSubmitting(true);
    setTimeLeft(30); // Задаем время отсчета

    // Обновляем таймер каждую секунду
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setIsSubmitting(false); // Разблокируем кнопку после завершения отсчета
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    axios
      .post(
        `https://api.telegram.org/bot5836893149:AAGwVrLzM9CTQOUXVe2eIPWpC70olIfUG0A/sendMessage`,
        {
          chat_id: "-1002354491119", // Обновленный ID чата
          text: message,
        }
      )
      .then(() => {
        alert("The application has been sent, we will call you back soon");
        handleClose(); // Закрываем форму после успешной отправки
      })
      .catch((error) => {
        console.error("Error sending request to Telegram", error);
        alert("Failed to send request");

        // В случае ошибки разблокируем кнопку и таймер сразу
        clearInterval(timer);
        setIsSubmitting(false);
        setTimeLeft(0);
      });
  };

  return (
    <>
      <IconButton
        className={`Supp_btn  ${showAnimatedButton ? "show" : ""}`}
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 1000,
          border: "5px #25d366 solid",
          color: "#25d366",
        }}
        onClick={handleClickOpen}
      >
        <CallIcon sx={{ fontSize: 40 }} />
      </IconButton>
      {showAnimatedButton && (
        <div className="comment-bubble">
          You can contact <br />
          us right now
        </div>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Support</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              name="phone"
              label="Phone"
              type="tel"
              fullWidth
              required
              value={formData.phone}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              required
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="question"
              label="Question"
              type="text"
              fullWidth
              value={formData.question}
              onChange={handleChange}
            />
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting}>
                {isSubmitting ? `Send (${timeLeft}s)` : "Send"}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SupportButton;
