import React from 'react'
import Footer from "../component/Footer/Footer";
import Navbar from "../component/Navbar/Navbar";
import Secton1 from "../component/Section/Secton1";
import Section2 from "../component/Section2/Section2";
import Section3 from "../component/Section3/Section3";
import Section4 from "../component/Section4/Section4";
import Section5 from "../component/Section5/Section5";
import Section6 from "../component/Section6/Section6";
import Header from '../component/Header/Header';
import SupportButton from '../component/Support_btn/Support_btn';




export default function Main() {
  return (
    <div>

    <SupportButton/>
    
    <Header/>
    <Secton1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section6 />
    <Footer />
    
    </div>
  )
}
