import React from 'react'
import Navbar from '../component/Navbar/Navbar'
import Contact from '../component/Contact/Contact'
import Footer from '../component/Footer/Footer'
import SupportButton from '../component/Support_btn/Support_btn'

export default function Contact_page() {
  return (
    <div>
    <Navbar/>
    <Contact/>
    <Footer/>
    <SupportButton/>
    
    </div>
  )
}
