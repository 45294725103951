import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PlayArrowIcon from "@mui/icons-material/PlayArrow"; // Импортируем иконку Play
import "./style.css";
import video1 from "./img/video/1.mp4";
import video2 from "./img/video/2.mp4";
import video3 from "./img/video/3.mp4";
import preview1 from "./img/z.jpg"; // Превью для видео 1
import preview2 from "./img/photo_2024-10-21_17-22-16zzz.jpg"; // Превью для видео 2
import preview3 from "./img/photo_2024-10-21_17-22-zz.jpg"; // Превью для видео 3
import { Link } from "react-router-dom";

const VideoWithPreview = ({ srcMp4, srcWebm, preview }) => {
  const [playing, setPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePlay = () => {
    setPlaying(true);
  };

  return (
    <div className="video-wrapper">
      {!playing && (
        <div className="preview-wrapper" onClick={handlePlay}>
          <img src={preview} alt="Video preview" className="video-preview" />
          <PlayArrowIcon className="play-icon" fontSize="large" /> {/* Иконка Play */}
        </div>
      )}

      {playing && (
        <>
          {loading && (
            <div className="loader-wrapper">
              <CircularProgress />
            </div>
          )}
          <video
            controls
            muted
            playsInline
            preload="metadata"
            className="video"
            onLoadedData={() => setLoading(false)}
          >
            <source src={srcMp4} type="video/mp4" />
            <source src={srcWebm} type="video/webm" />
          </video>
        </>
      )}
    </div>
  );
};

export default function Section3() {
  return (
    <div>
      <div className="sect3  pb-5">
        <div className="container">
          <div className="row">
            <h2 className="sect3__work-txt">Some of our works</h2>

            {[
              { mp4: video1, webm: "./img/video/1.webm", preview: preview1 },
              { mp4: video2, webm: "./img/video/2.webm", preview: preview2 },
              { mp4: video3, webm: "./img/video/3.webm", preview: preview3 }
            ].map((videoSrc, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <VideoWithPreview
                  srcMp4={videoSrc.mp4}
                  srcWebm={videoSrc.webm}
                  preview={videoSrc.preview}
                />
              </div>
            ))}

            <div className="sect3__btng">
              <Link to={'/Galleery'} className="link Link w-100">
                <button className="sect1__sch sect5__ab sect3__view">View our Gallery</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
