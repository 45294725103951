import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert} from '@mui/material';

import "./style.css";


export default function Secton1() {
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние модального окна
  const [formValues, setFormValues] = useState({
    phone: '',
    name: '',
    question: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Состояние для отслеживания отправки
  const [errorMessage, setErrorMessage] = useState(''); // Сообщение об ошибке
  const [cooldown, setCooldown] = useState(0); // Время до повторной отправки

  const groupChatId = '-1002354491119';
  const botToken = 'bot5836893149:AAGwVrLzM9CTQOUXVe2eIPWpC70olIfUG0A';

  // Открыть модальное окно
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Закрыть модальное окно
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Обработчик изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Функция для отправки сообщения в Telegram
  const sendMessageToTelegram = async () => {
    const message = `Новая заявка:\nИмя: ${formValues.name}\nТелефон: ${formValues.phone}\nВопрос: ${formValues.question}`;
    const url = `https://api.telegram.org/${botToken}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: groupChatId,
          text: message,
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при отправке сообщения');
      }

      return response.json();
    } catch (error) {
      console.error('Ошибка отправки сообщения в Telegram:', error);
      setErrorMessage('Не удалось отправить сообщение. Попробуйте позже.');
    }
  };

  // Обработчик отправки формы
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Проверяем, если еще действует таймер защиты от спама
    if (cooldown > 0) {
      setErrorMessage(`Подождите ${cooldown} секунд перед повторной отправкой.`);
      return;
    }

    setIsSubmitting(true); // Блокируем кнопку отправки

    // Отправляем сообщение в Telegram
    await sendMessageToTelegram();

    setIsSubmitting(false);
    handleCloseModal(); // Закрываем модальное окно после отправки формы

    // Устанавливаем задержку в 30 секунд
    setCooldown(30);
    const cooldownInterval = setInterval(() => {
      setCooldown((prevCooldown) => {
        if (prevCooldown <= 1) {
          clearInterval(cooldownInterval);
          return 0;
        }
        return prevCooldown - 1;
      });
    }, 1000);
  };

  return (
    <div>
      <div className="sect1">
        <div className="container">
          <div className="sect1__blocks">
            <h2>
              Reliable Cleaning and Pressure Washing Services for Exceptional
              Outcomes.
            </h2>
            <div className="sect1__com-block mt-5">
              <div className="sect__block-1">
                <p>
                Whether you are escaping from a successful home or business, we can help you with your home cleaning in Charlottesville, Waynesboro, Staunton & Harrisonburg, VA and the surrounding areas. BOBRIKOV CLEAN of Central VA values ​​perfect dry cleaning of your property. We always send you the same home and the same cleaning crew to provide you with the most reliable service. You can rest easy knowing that the same qualified crew provides consistently superior service.

Our well-trained staff provides excellent cleaning services. We thoroughly clean your belongings, whether you need cleaning on a regular basis, a one-time basis, or for a special occasion. You can rely on us to clean your home neatly and quickly.
                </p>
              </div>

              <div className="sect_block-2">
                <p>
                Take advantage of BOBRIKOV CLEAN services when you hire us for home cleaning in Charlottesville, Waynesboro, Staunton & Harrisonburg, VA and the surrounding areas. We use a special cleaning method to combat bacteria and odors that are commonly found in the bins and crevices of carpets. Our Enz-All chemicals remove 99.99% of germs from your home, including influenza and E. coli. They also control odors caused by environmental conditions such as cooking, smoking, and pets.
                </p>
              </div>

            </div>
      
      <Button
        variant="contained"
        color="primary"
        className="sect1__sch"
        
        onClick={handleOpenModal} // Открывает модальное окно при нажатии
        disabled={cooldown > 0} // Блокируем кнопку, если идет таймер задержки
      >
        {cooldown > 0 ? `Wait ${cooldown} second` : 'Schedule an Appointment'}
      </Button>

      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Support</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit}>
            <TextField
              autoFocus
              margin="dense"
              name="phone"
              label="Phone"
              type="tel"
              fullWidth
              required
              value={formValues.phone}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              required
              value={formValues.name}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="question"
              label="Question"
              type="text"
              fullWidth
              value={formValues.question}
              onChange={handleInputChange}
            />
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting || cooldown > 0}>
                {isSubmitting ? 'Sending...' : 'Send'}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}
