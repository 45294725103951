import React, { useState } from "react";
import "./style.css";

export default function Section6() {
  const [formValues, setFormValues] = useState({
    name: '',
    phone: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Для блокировки кнопки отправки
  const [errorMessage, setErrorMessage] = useState(''); // Сообщение об ошибке
  const [cooldown, setCooldown] = useState(0); // Время до повторной отправки

  const groupChatId = '-1002354491119';
  const botToken = 'bot5836893149:AAGwVrLzM9CTQOUXVe2eIPWpC70olIfUG0A';

  // Обработчик изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Функция для отправки сообщения в Telegram
  const sendMessageToTelegram = async () => {
    const message = `Запрос консультации:\nИмя: ${formValues.name}\nТелефон: ${formValues.phone}`;
    const url = `https://api.telegram.org/${botToken}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: groupChatId,
          text: message,
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при отправке сообщения');
      }

      return response.json();
    } catch (error) {
      console.error('Ошибка отправки сообщения в Telegram:', error);
      setErrorMessage('Не удалось отправить сообщение. Попробуйте позже.');
    }
  };

  // Обработчик отправки формы
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Проверяем, если еще действует таймер защиты от спама
    if (cooldown > 0) {
      setErrorMessage(`Подождите ${cooldown} секунд перед повторной отправкой.`);
      return;
    }

    setIsSubmitting(true); // Блокируем кнопку отправки

    // Отправляем сообщение в Telegram
    await sendMessageToTelegram();

    setIsSubmitting(false);

    // Устанавливаем задержку в 30 секунд
    setCooldown(30);
    const cooldownInterval = setInterval(() => {
      setCooldown((prevCooldown) => {
        if (prevCooldown <= 1) {
          clearInterval(cooldownInterval);
          return 0;
        }
        return prevCooldown - 1;
      });
    }, 1000);
  };

  return (
    <section className='section4 mt-5'>
      <div className="sect4__comm-block">
        <div className="sect4__text">
          <h1>Consultation of a specialist by phone</h1>
        </div>
        <div className="sect4__container">
          <div className="sect4__block-input">
            <form className="sect4__block-input" onSubmit={handleFormSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Your name"
                className='sect4__input'
                value={formValues.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="phone"
                placeholder="Your phone number*"
                className='sect4__input'
                value={formValues.phone}
                onChange={handleInputChange}
                required
              />
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <button type="submit" disabled={isSubmitting || cooldown > 0}>
                {cooldown > 0 ? `Wait ${cooldown} seconds` : 'Give me a call'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
