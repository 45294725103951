import React from "react";
import "./style.css";

export default function Footer() {
  return (
    <div>
      <footer class="site-footer">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h6>Contact Us</h6>
              <p>
                Phone: <a href="tel:+19455991844">+1 945-599-18-44</a>
              </p>
              <p>
                Phone: <a href="tel:+12532046181">+1 (253) 204-6181</a>
              </p>
              <div className="footer__contact-via">
                <p>Contact via:</p>
                <ul class="social-icons">
                  <li>
                    <a class="whatsapp" href="https://wa.me/19455991844">
                      <i class="fa fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a class="viber" href="tel:+12532046181">
                      <i class="fa-brands fa-viber"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-6 col-xs-12">
              <p class="copyright-text">
                Copyright &copy; 2024 All Rights Reserved by
                <a href="#">BOBRIKOVCLEAN</a>.
              </p>
            </div>

            {/* <div class="col-md-4 col-sm-6 col-xs-12">
              <ul class="social-icons">
                <li>
                  <a class="facebook" href="#">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a class="linkedin" href="#">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a class="whatsapp" href="https://wa.me/19455991844">
                    <i class="fa fa-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a class="viber" href="viber://add?number=+19455991844">
                    <i class="fa-brands fa-viber"></i>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}
