import logo from "./img/logo.webp";
import ins from "./img/insta.svg";
import React, { useState, useEffect } from "react";

import axios from "axios";
import img2 from "./img/2.JPG";
import img3 from "./img/3.webp";
import img5 from "./img/5.jpeg";
import img6 from "./img/fon5.jpg";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";

export default function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние модального окна
  const [formValues, setFormValues] = useState({
    phone: "",
    name: "",
    question: "",
  });
  const [isSubmitting2, setIsSubmitting2] = useState(false); // Состояние для отслеживания отправки
  const [errorMessage, setErrorMessage] = useState(""); // Сообщение об ошибке
  const [cooldown, setCooldown] = useState(0); // Время до повторной отправки

  const groupChatId = "-1002354491119";
  const botToken = "bot5836893149:AAGwVrLzM9CTQOUXVe2eIPWpC70olIfUG0A";

  // Открыть модальное окно
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Закрыть модальное окно
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Обработчик изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  // Функция для отправки сообщения в Telegram
  const sendMessageToTelegram = async () => {
    const message = `Новая заявка:\nИмя: ${formValues.name}\nТелефон: ${formValues.phone}\nВопрос: ${formValues.question}`;
    const url = `https://api.telegram.org/${botToken}/sendMessage`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: groupChatId,
          text: message,
        }),
      });

      if (!response.ok) {
        throw new Error("Ошибка при отправке сообщения");
      }

      return response.json();
    } catch (error) {
      console.error("Ошибка отправки сообщения в Telegram:", error);
      setErrorMessage("Не удалось отправить сообщение. Попробуйте позже.");
    }
  };

  // Обработчик отправки формы
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Проверяем, если еще действует таймер защиты от спама
    if (cooldown > 0) {
      setErrorMessage(
        `Подождите ${cooldown} секунд перед повторной отправкой.`
      );
      return;
    }

    setIsSubmitting2(true); // Блокируем кнопку отправки

    // Отправляем сообщение в Telegram
    await sendMessageToTelegram();

    setIsSubmitting2(false);
    handleCloseModal(); // Закрываем модальное окно после отправки формы

    // Устанавливаем задержку в 30 секунд
    setCooldown(30);
    const cooldownInterval = setInterval(() => {
      setCooldown((prevCooldown) => {
        if (prevCooldown <= 1) {
          clearInterval(cooldownInterval);
          return 0;
        }
        return prevCooldown - 1;
      });
    }, 1000);
  };

  const [selectedService, setSelectedService] = React.useState("");
  const [formData, setFormData] = useState({
    fullname: "",
    number: "",
    email: "",
  });
  const [errors, setErrors] = useState({
    number: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Флаг для блокировки кнопки
  const [timeLeft, setTimeLeft] = useState(0); // Оставшееся время до разблокировки

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Сброс ошибки при вводе номера телефона
    if (e.target.name === "number") {
      setErrors((prevErrors) => ({ ...prevErrors, number: false }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!formData.number) {
      // Если номер не введен, показать ошибку
      setErrors((prevErrors) => ({ ...prevErrors, number: true }));
      return; // Остановить выполнение отправки формы
    }

    const message = `
      Full name: ${formData.fullname}
      Phone number: ${formData.number}
      Email: ${formData.email}
      Service: ${selectedService}
    `;

    // Блокируем кнопку и задаем таймер на 30 секунд
    setIsSubmitting(true);
    setTimeLeft(30); // Задаем время отсчета

    // Обновляем таймер каждую секунду
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setIsSubmitting(false); // Разблокируем кнопку после завершения отсчета
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    axios
      .post(
        `https://api.telegram.org/bot5836893149:AAGwVrLzM9CTQOUXVe2eIPWpC70olIfUG0A/sendMessage`,
        {
          chat_id: "-1002354491119", // Обновленный ID чата
          text: message,
        }
      )
      .then(() => {
        alert("The application has been sent, we will call you back soon");
      })
      .catch((error) => {
        console.error("Error sending request to Telegram", error);
        alert("Failed to send request");

        // В случае ошибки разблокируем кнопку и таймер сразу
        clearInterval(timer);
        setIsSubmitting(false);
        setTimeLeft(0);
      });
  };

  const [backgroundImage, setBackgroundImage] = useState(); // Изначально устанавливаем фон img5

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setBackgroundImage(img6); // Меняем фон на img6, если ширина экрана <= 480px
      } else {
        setBackgroundImage(img5); // Возвращаем фон img5 при ширине экрана больше 480px
      }
    };

    handleResize(); // Проверяем начальное состояние экрана

    window.addEventListener("resize", handleResize); // Добавляем слушатель изменения размера экрана

    return () => {
      window.removeEventListener("resize", handleResize); // Очищаем слушатель при размонтировании компонента
    };
  }, []);
  return (
    <div>
      <div
        className="header-container"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "110% auto", // Увеличиваем размер фона на 10%
          backgroundPosition: "35% 72%", // Сдвигаем изображение вниз на 20% по вертикали
          height: "100vh",
          position: "relative",
        }}
      >
        {/* <div
      className="header-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '110% auto', // Увеличиваем размер фона на 10%
        backgroundPosition: '35% 72%', // Сдвигаем изображение вниз на 20% по вертикали
        height: '100vh',
        position: 'relative',
        transition: 'background-image 1s ease-in-out',
      }}
    >
    </div> */}
        <Navbar />
        <div className="container">
          <div className="header">
            <div className="header__blocks">
              <div className="header__block-left">
                <div className="header__block-left-txtp">
                  <p>Professional dry cleaning of upholstered furniture</p>
                  <Typography
                    variant="txt"
                    sx={{
                      fontSize: "clamp(40px, 5vw, 190px)",
                      lineHeight: "clamp(40px, 5vw, 200px)",
                      textAlign: "start",
                    }}
                    className="header__block-left-h1"
                  >
                    Deep cleaning of all your furniture
                  </Typography>
                </div>
                <div className="header__block-left-btn">
                  <Button
                    variant="contained"
                    color="primary"
                    className="header__block-left-btn1"
                    sx={{ marginRight: "30px" }}
                    onClick={handleOpenModal} // Открывает модальное окно при нажатии
                    disabled={cooldown > 0} // Блокируем кнопку, если идет таймер задержки
                  >
                    {cooldown > 0
                      ? `Подождите ${cooldown} секунд`
                      : "Schedule an Appointment"}
                  </Button>

                  <Dialog open={isModalOpen} onClose={handleCloseModal}>
                    <DialogTitle>Support</DialogTitle>
                    <DialogContent>
                      <form onSubmit={handleFormSubmit}>
                        <TextField
                          autoFocus
                          margin="dense"
                          name="phone"
                          label="Phone"
                          type="tel"
                          fullWidth
                          required
                          value={formValues.phone}
                          onChange={handleInputChange}
                        />
                        <TextField
                          margin="dense"
                          name="name"
                          label="Name"
                          type="text"
                          fullWidth
                          required
                          value={formValues.name}
                          onChange={handleInputChange}
                        />
                        <TextField
                          margin="dense"
                          name="question"
                          label="Question"
                          type="text"
                          fullWidth
                          value={formValues.question}
                          onChange={handleInputChange}
                        />
                        {errorMessage && (
                          <Alert severity="error">{errorMessage}</Alert>
                        )}
                        <DialogActions>
                          <Button onClick={handleCloseModal} color="primary">
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={isSubmitting || cooldown > 0}
                          >
                            {isSubmitting ? "Sending..." : "Send"}
                          </Button>
                        </DialogActions>
                      </form>
                    </DialogContent>
                  </Dialog>
                  <a href="tel:+12532046181">
                    <Button
                      variant="contained"
                      color="primary"
                      className="header__block-left-btn2"
                    >
                      Click to call
                    </Button>
                  </a>
                  {/* <a href="tel:+19455991844">
                    <Button
                      sx={{ color: "white", border: "1px white solid" }}
                      variant="outlined"
                      color="primary"
                      className="header__block-left-btn2"
                    >
                      Click to call
                    </Button>
                  </a> */}
                </div>
              </div>
              <div className="header__block-right">
                <form className="form" onSubmit={handleSubmit}>
                  <TextField
                    label="Full name"
                    name="fullname"
                    placeholder="Enter full name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.fullname}
                    onChange={handleChange}
                  />
                  <TextField
                    label="Number"
                    name="number"
                    placeholder="Enter phone number *"
                    type="tel"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.number}
                    onChange={handleChange}
                    error={errors.number} // Показываем ошибку, если поле пустое
                    helperText={errors.number ? "Phone number is required" : ""}
                    InputLabelProps={{
                      style: errors.number ? { color: "red" } : {}, // Меняем цвет текста на красный, если ошибка
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={isSubmitting2} // Блокируем кнопку на 30 секунд
                  >
                    {isSubmitting2
                      ? `Please wait... ${timeLeft}s`
                      : "Call back"}
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
