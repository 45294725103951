import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import "./style.css";
import img1 from "./img/1.jpg";
import img2 from "./img/2.jpg";
import img3 from "./img/3.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/5.jpg";
import img6 from "./img/6.jpg";
import img7 from "./img/7.jpg";
import img8 from "./img/8.jpg";
import img9 from "./img/9.jpg";
import img10 from "./img/10.jpg";
import img11 from "./img/11.jpg";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const itemData = [
  { img: img1, title: "Breakfast", rows: 4, cols: 2 },
  { img: img2, title: "Burger", rows: 4, cols: 2 },
  { img: img3, title: "Camera", rows: 4, cols: 2 },
  { img: img4, title: "Coffee", rows: 2, cols: 2 },
  { img: img5, title: "Hats", rows: 2, cols: 2 },
  { img: img6, title: "Honey", rows: 2, cols: 2 },
  { img: img7, title: "Basketball", rows: 3, cols: 2 },
  { img: img8, title: "Fern", rows: 3, cols: 2 },
  { img: img9, title: "Mushrooms", rows: 3, cols: 2 },
  { img: img10, title: "Tomato basil", rows: 3, cols: 2 },
  { img: img11, title: "Sea star", rows: 2, cols: 2 },
];

export default function Section4() {
  return (
    <div>
      <div className="sect4 mt-5">
        <div className="container">
          <div className="sect4__blocks-all">
            <div className="sect4__main-txt pb-5">
              <h2>«bobrikovclean» - is a cleaning company</h2>
              <p>
                With many years of cleaning experience, we help people become
                happier and freer, get rid of the unpleasant and boring job of
                cleaning. You will be able to devote your free time to family,
                friends, recreation, doing what you love, and we will do all the
                dirty work for you.
              </p>
            </div>
            <div className="sect4__block-right">
              <img src={img2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuiltedImageList() {
  return (
    <div className="sect4">
      <div className="container">
        <ImageList
          sx={{ width: "100%", height: 650 }}
          variant="quilted"
          cols={4}
          rowHeight={121}
        >
          {itemData.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      <div className="sect4__btn">
        <button className="sect1__sch sect4_btnview ">Examples of work </button>
      </div>
    </div>
  );
}
